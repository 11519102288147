import {
    NumberInput,
    NumberField,
    SelectField,
    SelectInput,
    LongTextInput,
    TextInput,
    TextField,
    required,
} from 'react-admin';

export const empty_field = {
    props: {
        source: "empty_field",
        label: " ",
    },
    editComponent: TextInput,
    showComponent: TextField,
};
export const minsum = {
    props: {
        source: "min_sum",
        label: "Megjelenítés Minimum Összeg",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const maxsum = {
    props: {
        source: "max_sum",
        label: "Megjelenítés Maximum Összeg",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const minmonths = {
    props: {
        source: "min_months",
        label: "Megjelenítés minimum Hónap",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const maxmonths = {
    props: {
        source: "max_months",
        label: "Megjelenítés maximum Hónap",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const min_lekotheto_osszeg = {
    props: {
        source: "min_lekotheto_osszeg",
        label: "Minimum leköthető összeg",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const max_lekotheto_osszeg = {
    props: {
        source: "max_lekotheto_osszeg",
        label: "Maximum leköthető összeg",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const effective_months = {
    props: {
        source: "effective_months",
        label: "Számítás min-max hónap",
        tooltip: "Számításnál használandó hónap érték (pl. 20, vagy 20-30, vagy 12,15,20). Amennyiben ki van töltve, a kalkulátor ezzel számol és kiírja hogy X hónapra számítva",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const effective_sum = {
    props: {
        source: "effective_sum",
        label: "Számítás min-max összeg",
        tooltip: "Számításnál használandó osszeg érték (pl. 200000, vagy 2000-300000). Amennyiben ki van töltve, a kalkulátor ezzel számol és kiírja hogy X összegre számítva",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const minage = {
    props: {
        source: "min_age",
        label: "Minimum Életkor",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const maxage = {
    props: {
        source: "max_age",
        label: "Maximum Életkor",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const minincome = {
    props: {
        source: "min_income",
        label: "Elvárt Jövedelem",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const minworkmonth = {
    props: {
        source: "min_work_months",
        label: "Elvárt Munkaviszony (hó)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const inkhr = {
    props: {
        source: "in_khr",
        label: "Negatív KHR-es igényelhet?",
        choices: [
            { id: 0, name: "Nem" },
            { id: 1, name: "Igen" },
        ]
    },
    defaultValue: {
        defaultValue: 0,
    },
    editComponent: SelectInput,
    showComponent: SelectField,
};

export const income2bank = {
    props: {
        source: "income2bank",
        label: "Jövedelem áthozása szükséges a bankba?",
        choices: [
            { id: 0, name: "Nem" },
            { id: 1, name: "Igen" },
        ]
    },
    defaultValue: {
        defaultValue: 0,
    },
    editComponent: SelectInput,
    showComponent: SelectField,
};

export const partner_req = {
    props: {
        source: "partner_req",
        label: "Házastárs bevonása szükséges?",
        choices: [
            { id: 0, name: "Nem" },
            { id: 1, name: "Igen" },
        ]
    },
    defaultValue: {
        defaultValue: 0,
    },
    editComponent: SelectInput,
    showComponent: SelectField,
};

export const promo_offer = {
    props: {
        source: "promo_offer",
        label: "Akciós?",
    },
    editComponent: LongTextInput,
    showComponent: TextField,
};

export const payment_period = {
    props: {
       source: "payment_period",
       label: "Törlesztés",
       choices: [
           { id: 0, name: "Havi" },
           { id: 1, name: "Heti" },
       ]
   },
   defaultValue: {
       defaultValue: 0,
   },
   editComponent: SelectInput,
   showComponent: SelectField,
};

export const monthly_costs = {
    props: {
        source: "monthly_costs",
        label: "Számlavezetési díj",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const disbursement_percent = {
    props: {
        source: "disbursement_percent",
        label: "Folyósítási díj (%)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const annual_interest = {
    props: {
        source: "annual_interest",
        label: "Éves Kamat",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const kamatado = {
    props: {
        source: "kamatado",
        label: "Kamatadó mértéke",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const szocho = {
    props: {
        source: "szocho",
        label: "Szochó mértéke",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const interest_period = {
    props: {
        source: "interest_period",
        label: "Kamat periódus",
        choices: [
            { id: 0, name: "Fix" },
            { id: 1, name: "Változó" },
            { id: 2, name: "3 hónap" },
            { id: 3, name: "6 hónap" },
            { id: 4, name: "12 hónap" },
            { id: 5, name: "3 év" },
            { id: 6, name: "5 év" },
            { id: 7, name: "10 év" },
            { id: 8, name: "15 év" },
            { id: 9, name: "20 év" },
            { id: 10, name: "A futamidő első két évében 1 év, azt követően 5 év" },
        ]
    },
    defaultValue: {
        defaultValue: 0,
    },
    editComponent: SelectInput,
    showComponent: SelectField,
};

export const interest_type = {
    props: {
        source: "interest_type",
        label: "Kamat Típusa",
        choices: [
            { id: 0, name: "Fix" },
            { id: 1, name: "Változó" },
        ]
    },
    defaultValue: {
        defaultValue: 0,
    },
    editComponent: SelectInput,
    showComponent: SelectField,
};

export const other_costs = {
    props: {
        source: "other_costs",
        label: "Egyéb költségek",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const other_info = {
    props: {
        source: "other_info",
        label: "Előtörlesztési információ",
    },
    editComponent: LongTextInput,
    showComponent: TextField,
};

export const example_text = {
    props: {
        source: "example_text",
        label: "Reprezentatív példa",
    },
    editComponent: LongTextInput,
    showComponent: TextField,
};

export const real_estate_type = {
    props: {
        source: "real_estate_type",
        label: "Lakás típusa",
        choices: [
            { id: 0, name: "Új lakás" },
            { id: 1, name: "Használt lakás" },
        ]
    },
    defaultValue: {
        defaultValue: 0,
    },
    editComponent: SelectInput,
    showComponent: SelectField,
};

export const account_type = {
    props: {
        source: "account_type",
        label: "Bankszámla típusa",
        choices: [
            { id: 0, name:  "Ingyenes folyószámla vezetés" },
            { id: 1, name:  "Akciós folyószámla" },
            { id: 2, name:  "Kedvező átutalási költség" },
            { id: 3, name:  "Prémium folyószámla" },
        ],
        allowEmpty: true,
    },
    editComponent: SelectInput,
    showComponent: SelectField,
};

export const account_type_creditcard = {
    props: {
        source: "account_type",
        label: "Hitelkártya típusa",
        choices: [
            { id: 4, name:  "Pontgyűjtő" },
            { id: 5, name:  "Akciós" },
            { id: 6, name:  "Aranykártya" },
            { id: 7, name:  "Kedvező tankolás" },
        ],
        allowEmpty: true,
    },
    editComponent: SelectInput,
    showComponent: SelectField,
};

export const credit_type = {
    props: {
        source: "credit_type",
        label: "Hitel típusa",
        choices: [
            { id: 0, name: "Minősített fogyasztóbarát lakáshitel" },
            { id: 1, name: "Piaci kamatozású lakáshitel" },
            { id: 2, name: "CSOK és lakáshitel" },
            { id: 3, name: "Szabad felhasználású jelzáloghitel" },
        ]
    },
    editComponent: SelectInput,
    showComponent: SelectField,
};

export const credit_type_vallalkozoi = {
    props: {
        source: "credit_type",
        label: "Hitelcél",
        choices: [
            { id: 4, name: "Széchenyi Folyószámlahitel Plusz" },
            { id: 7, name: "Munkahelymegtartó hitel" },
            { id: 8, name: "Likviditás hitel" },
            { id: 9, name: "Beruházási hitel plusz" },
            { id: 10, name: "Önerő kiegészítő hitel" },
            { id: 11, name: "Támogatást Megelőlegező hitel" },
            { id: 12, name: "Széchenyi Turisztikai Kártya"},
            { id: 13, name: "Agrár Beruházási hitel plusz"},
            { id: 14, name: "Mikrohitel"},
            { id: 15, name: "Lízing"},
            { id: 16, name: "Széchenyi Kártya Folyószámlahitel MAX"},
            { id: 17, name: "Széchenyi Beruházási Hitel MAX"},
            { id: 18, name: "Széchenyi Likviditási Hitel MAX"},
            { id: 19, name: "Széchenyi Mikrohitel MAX"},
            { id: 20, name: "Széchenyi Lízingkonstrukció MAX"},
            { id: 21, name: "Agrár Széchenyi Beruházási Hitel MAX"},
            { id: 22, name: "Agrár Széchenyi Kártya"},
        ]
    },
    editComponent: SelectInput,
    showComponent: SelectField,
};

export const gov_interest_support = {
    props: {
        source: "gov_interest_support",
        label: "Állami kamattámogatás",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const valuation_fee = {
    props: {
        source: "valuation_fee",
        label: "Értékbecslési díj",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const certificate_fee = {
    props: {
        source: "certificate_fee",
        label: "Tulajdoni lap díj",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const map_fee = {
    props: {
        source: "map_fee",
        label: "Térképmásolat",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const mortgage_fee = {
    props: {
        source: "mortgage_fee",
        label: "Jelzálog bejegyzési kérelem",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const transfer_fee = {
    props: {
        source: "transfer_fee",
        label: "Átutalási díj",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const health_insurance = {
    props: {
        source: "health_insurance",
        label: "TB Jogviszony szükséges?",
        choices: [
            { id: 0, name: "Nem" },
            { id: 10, name: "3 hónap"},
            { id: 1, name: "1 év" },
            { id: 2, name: "2 év" },
            { id: 3, name: "3 év" },
            { id: 4, name: "4 év" },
            { id: 5, name: "5 év" },
            { id: 6, name: "6 év" },
            { id: 7, name: "7 év" },
            { id: 8, name: "8 év" },
            { id: 9, name: "9 év" },
        ]
    },
    defaultValue: {
        defaultValue: 0,
    },
    editComponent: SelectInput,
    showComponent: SelectField,
};

export const guarantee_fee = {
    props: {
        source: "guarantee_fee",
        label: "Kezességvállalási díj",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const annual_interest_other = {
    props: {
        source: "annual_interest_other",
        label: "Kamattámogatás megszűnése esetén",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const internet_bank = {
    props: {
        source: "internet_bank",
        label: "Internetbank igénylés díja",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const no_interest_days = {
    props: {
        source: "no_interest_days",
        label: "Kamatmentes időszak",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const flat_rate_thm = {
    props: {
        source: "flat_rate_thm",
        label: "THM",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const credit_limit = {
    props: {
        source: "credit_limit",
        label: "Hitelkeret összege",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const fresh_company = {
    props: {
        source: "fresh_company",
        label: "Kezdő vállalkozás igényelheti",
        choices: [
            { id: 0, name: "Nem" },
            { id: 1, name: "Igen" },
        ]
    },
    defaultValue: {
        defaultValue: 0,
    },
    editComponent: SelectInput,
    showComponent: SelectField,
}

export const business_years = {
    props: {
        source: "business_years",
        label: "Meglévő/lezárt üzleti év",
        choices: [
            { id: 0, name: "1 év" },
            { id: 1, name: "2 év" },
        ],
        allowEmpty: true,
    },
    editComponent: SelectInput,
    showComponent: SelectField,
}

export const equity_cover = {
    props: {
        source: "equity_cover",
        label: "Ingatlanfedezet szükséges",
        choices: [
            { id: 0, name: "Nem" },
            { id: 1, name: "Igen" },
        ]
    },
    defaultValue: {
        defaultValue: 0,
    },
    editComponent: SelectInput,
    showComponent: SelectField,
}

export const owner_guarantee = {
    props: {
        source: "owner_guarantee",
        label: "Tulajdonosi kezességvállalás",
        choices: [
            { id: 0, name: "Nem" },
            { id: 1, name: "Igen" },
        ]
    },
    defaultValue: {
        defaultValue: 0,
    },
    editComponent: SelectInput,
    showComponent: SelectField,
}

export const guarantee_type = {
    props: {
        source: "guarantee_type",
        label: "Garantiqa/avgha/egyéb garancia",
        choices: [
            { id: 0, name: "Nem" },
            { id: 1, name: "Igen" },
        ]
    },
    defaultValue: {
        defaultValue: 0,
    },
    editComponent: SelectInput,
    showComponent: SelectField,
}

export const currency = {
    props: {
        source: "currency",
        label: "Devizanem",
        choices: [
            { id: 0, name: "HUF" },
            { id: 1, name: "EUR" },
        ]
    },
    defaultValue: {
        defaultValue: 0,
    },
    editComponent: SelectInput,
    showComponent: SelectField,
}

export const decision_fee = {
    props: {
        source: "decision_fee",
        label: "Hitel bírálati díja",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const administration_fee = {
    props: {
        source: "administration_fee",
        label: "Adminisztrációs költség",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const contract_fee = {
    props: {
        source: "contract_fee",
        label: "Szerződéskötési díj",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const payout_fee = {
    props: {
        source: "payout_fee",
        label: "Folyósítási jutalék",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const handling_fee = {
    props: {
        source: "handling_fee",
        label: "Kezelési költség (évi)",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const repayment_fee = {
    props: {
        source: "repayment_fee",
        label: "Előtörlesztési díj",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const full_payment_fee = {
    props: {
        source: "full_payment_fee",
        label: "Végtörlesztési díj",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const other_comment = {
    props: {
        source: "other_comment",
        label: "Egyéb megjegyzés",
    },
    editComponent: LongTextInput,
    showComponent: TextField,
};

export const other_comment_betet = {
    props: {
        source: "other_comment",
        label: "Kamatozás ismertetés",
    },
    editComponent: LongTextInput,
    showComponent: TextField,
};

export const betetfeltores = {
    props: {
        source: "betetfeltores",
        label: "Betétfeltörés",
    },
    editComponent: LongTextInput,
    showComponent: TextField,
};


export const late_fee = {
    props: {
        source: "late_fee",
        label: "Késedelmi kamat (évi)",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const garantiqa_fee = {
    props: {
        source: "garantiqa_fee",
        label: "Készfizető kezességvállalás díja (évi)",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const guarantor_name = {
    props: {
        source: "guarantor_name",
        label: "Garantőr",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const checkup_fee = {
    props: {
        source: "checkup_fee",
        label: "Hitelfedezet éves felülvizsgálatának díja",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const closing_fee = {
    props: {
        source: "closing_fee",
        label: "Zárlati díj",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const commitment_fee = {
    props: {
        source: "commitment_fee",
        label: "Rendelkezésre tartási jutalék (évi)",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const cardname = {
    props: {
        source: "cardname",
        label: "Bankkártya neve",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const ebkm = {
    props: {
        source: "ebkm",
        label: "EBKM",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const main_card_first_year_fee_card = {
    props: {
        source: "main_card_first_year_fee_card",
        label: "Főkártya első éves díja",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const main_card_second_year_fee_card = {
    props: {
        source: "main_card_second_year_fee_card",
        label: "Főkártya éves díja a második évtől",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const card_issueing_fee = {
    props: {
        source: "card_issueing_fee",
        label: "Bankkártya kibocsátási díj",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_opening_fee = {
    props: {
        source: "account_opening_fee",
        label: "Számlanyitási díj",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const electronic_statement_fee = {
    props: {
        source: "electronic_statement_fee",
        label: "Elektronikus számlakivonat díja",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const internetbank_fee = {
    props: {
        source: "internetbank_fee",
        label: "Internet- és mobilbank díja",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const purchase_with_card = {
    props: {
        source: "purchase_with_card",
        label: "Bankkártyás vásárlás díja",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const prompt_collection_fee = {
    props: {
        source: "prompt_collection_fee",
        label: "Beszedési megbízás díja",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const normal_wire_transfer_fee = {
    props: {
        source: "normal_wire_transfer_fee",
        label: "Elektronikus átutalás díja (bankon kívül)",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const regular_wire_transfer_fee = {
    props: {
        source: "regular_wire_transfer_fee",
        label: "Rendszeres átutalás (bankon kívül)",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const cash_withdraw_own_atm = {
    props: {
        source: "cash_withdraw_own_atm",
        label: "Készpénzfelvétel saját ATM-ből",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const cash_withdraw_foreign_atm = {
    props: {
        source: "cash_withdraw_foreign_atm",
        label: "Készpénzfelvétel idegen ATM-ből",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const governmental_fee = {
    props: {
        source: "governmental_fee",
        label: "Hatósági átutalás díja",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const calc_fix_extra = {
    props: {
        source: "calc_fix_extra",
        label: "Egyéb fix költség a folyósitáskor (speciális)",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const calc_fix = {
    props: {
        source: "calc_fix",
        label: "Össz fix költség a folyósitáskor (Ft)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const calc_monthly = {
    props: {
        source: "calc_monthly",
        label: "Plusz havi törlesztő (Ft)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const min_interest = {
    props: {
        source: "min_interest",
        label: "Minimum Kamat",
        validate: required() ,
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const max_interest = {
    props: {
        source: "max_interest",
        label: "Maximum Kamat",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const min_sum = {
    props: {
        source: "min_sum",
        label: "Minimum Összeg",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const max_sum = {
    props: {
        source: "max_sum",
        label: "Maximum Összeg",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const min_months = {
    props: {
        source: "min_months",
        label: "Minimum Futamidő (hó)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const max_months = {
    props: {
        source: "max_months",
        label: "Maximum Futamidő (hó)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const min_income = {
    props: {
        source: "min_income",
        label: "Minimum Jövedelem",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const max_income = {
    props: {
        source: "max_income",
        label: "Maximum Jövedelem",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const min_income_bankaccount_private = {
    props: {
        source: "min_income",
        label: "Minimum Havi Jóváírás",
        validate: required() ,
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const max_income_bankaccount_private = {
    props: {
        source: "max_income",
        label: "Maximum Havi Jóváírás",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const min_income_bankaccount_company = {
    props: {
        source: "min_income",
        label: "Minimum éves árbevétel",
        validate: required() ,
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const max_income_bankaccount_company = {
    props: {
        source: "max_income",
        label: "Maximum éves árbevétel",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};


export const min_thm = {
    props: {
        source: "min_thm",
        label: "Minimum THM",
        validate: required() ,
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const max_thm = {
    props: {
        source: "max_thm",
        label: "Maximum THM",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const title = {
    props: {
        source: "title",
        label: "Termék Neve",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const credit_min_repayment = {
    props: {
        source: "credit_min_repayment",
        label: "Minimum visszafizetendő összeg",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const credit_interest_monthly = {
    props: {
        source: "credit_interest_monthly",
        label: "Havi hitelkamat",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const cashback_max = {
    props: {
        source: "cashback_max",
        label: "Pénzvisszatérítés maximum összege",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const creditcard_groceries = {
    props: {
        source: "creditcard_groceries",
        label: "Élelmiszer",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const creditcard_petrol = {
    props: {
        source: "creditcard_petrol",
        label: "Benzinkút",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const creditcard_home = {
    props: {
        source: "creditcard_home",
        label: "Otthon",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const creditcard_travel = {
    props: {
        source: "creditcard_travel",
        label: "Utazás",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const creditcard_electronics = {
    props: {
        source: "creditcard_electronics",
        label: "Elektronika",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const creditcard_dress = {
    props: {
        source: "creditcard_dress",
        label: "Ruházat",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const creditcard_culture = {
    props: {
        source: "creditcard_culture",
        label: "Kultúra és szórakozás",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const creditcard_sport = {
    props: {
        source: "creditcard_sport",
        label: "Sport és egészség",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const min_credit = {
    props: {
        source: "min_credit",
        label: "Minimum Hitelkeret",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const max_credit = {
    props: {
        source: "max_credit",
        label: "Maximum Hitelkeret",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const real_interest = {
    props: {
        source: "real_interest",
        label: "Ügyleti kamat",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const handling_fee_support = {
    props: {
        source: "handling_fee_support",
        label: "Kezelési költségtámogatás",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const handling_fee_net = {
    props: {
        source: "handling_fee_net",
        label: "Nettó kezelési költség",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const surety_fee = {
    props: {
        source: "surety_fee",
        label: "Készfizető kezesség mértéke",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const package_fee = {
    props: {
        source: "package_fee",
        label: "Csomagdíj",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const loan_annuity = {
    props: {
        source: "loan_annuity",
        label: "Annuitásos Hitel",
        choices: [
            { id: 0, name: "Nem" },
            { id: 1, name: "Igen" },
        ]
    },
    defaultValue: {
        defaultValue: 0,
    },
    editComponent: SelectInput,
    showComponent: SelectField,
};

export const interest_only_first_year = {
    props: {
        source: "interest_only_first_year",
        label: "Első évben csak kamattörlesztés",
        choices: [
            { id: 0, name: "Nem" },
            { id: 1, name: "Igen" },
        ]
    },
    defaultValue: {
        defaultValue: 0,
    },
    editComponent: SelectInput,
    showComponent: SelectField,
};

export const interest_rate_change_indicator = {
    props: {
        source: "interest_rate_change_indicator",
        label: "Kamatváltoztatási mutató",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const interest_surcharge_change_indicator = {
    props: {
        source: "interest_surcharge_change_indicator",
        label: "Kamatfelár változtatási mutató",
    },
    editComponent: TextInput,
    showComponent: TextField,
};

export const mortgage_cancellation_fee = {
    props: {
        source: "mortgage_cancellation_fee",
        label: "Jelzálogjog törlése",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_card_use_fee = {
    props: {
        source: "account_card_use_fee",
        label: "Bankkártyás vásárlás díja",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_collect_fee = {
    props: {
        source: "account_collect_fee",
        label: "Beszedési megbízás díja (Ft)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_collect_percent = {
    props: {
        source: "account_collect_percent",
        label: "Beszedési megbízás díja (%)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_collect_max = {
    props: {
        source: "account_collect_max",
        label: "Beszedési megbízás max",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_collect_min = {
    props: {
        source: "account_collect_min",
        label: "Beszedési megbízás min",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_collect_transaction_fee = {
    props: {
        source: "account_collect_transaction_fee",
        label: "Beszedési megbízás tranzakciós díj (Ft)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_collect_transaction_percent = {
    props: {
        source: "account_collect_transaction_percent",
        label: "Beszedési megbízás tranzakciós díj (%)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_collect_transaction_max = {
    props: {
        source: "account_collect_transaction_max",
        label: "Beszedési megbízás tranzakciós díj max",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_abroad_atm_fee = {
    props: {
        source: "account_abroad_atm_fee",
        label: "Készpénzfelvétel külföldi ATM-ből (Ft)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_abroad_atm_percent = {
    props: {
        source: "account_abroad_atm_percent",
        label: "Készpénzfelvétel külföldi ATM-ből (%)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_abroad_atm_max = {
    props: {
        source: "account_abroad_atm_max",
        label: "Készpénzfelvétel külföldi ATM-ből max",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_abroad_atm_min = {
    props: {
        source: "account_abroad_atm_min",
        label: "Készpénzfelvétel külföldi ATM-ből min",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_abroad_atm_transaction_percent = {
    props: {
        source: "account_abroad_atm_transaction_percent",
        label: "Készpénzfelvétel külföldi ATM-ből tranzakciós díj (%)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_foreign_atm_fee = {
    props: {
        source: "account_foreign_atm_fee",
        label: "Készpénzfelvétel idegen ATM-ből (Ft)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_foreign_atm_percent = {
    props: {
        source: "account_foreign_atm_percent",
        label: "Készpénzfelvétel idegen ATM-ből (%)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_foreign_atm_max = {
    props: {
        source: "account_foreign_atm_max",
        label: "Készpénzfelvétel idegen ATM-ből max",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_foreign_atm_min = {
    props: {
        source: "account_foreign_atm_min",
        label: "Készpénzfelvétel idegen ATM-ből min",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_foreign_atm_transaction_percent = {
    props: {
        source: "account_foreign_atm_transaction_percent",
        label: "Készpénzfelvétel idegen ATM-ből tranzakciós díj (%)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_own_atm_fee = {
    props: {
        source: "account_own_atm_fee",
        label: "Készpénzfelvétel saját ATM-ből (Ft)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_own_atm_percent = {
    props: {
        source: "account_own_atm_percent",
        label: "Készpénzfelvétel saját ATM-ből (%)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_own_atm_max = {
    props: {
        source: "account_own_atm_max",
        label: "Készpénzfelvétel saját ATM-ből max",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_own_atm_min = {
    props: {
        source: "account_own_atm_min",
        label: "Készpénzfelvétel saját ATM-ből min",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_own_atm_transaction_percent = {
    props: {
        source: "account_own_atm_transaction_percent",
        label: "Készpénzfelvétel saját ATM-ből tranzakciós díj (%)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_wire_fee = {
    props: {
        source: "account_wire_fee",
        label: "Elektronikus átutalás díja (Ft)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_wire_max = {
    props: {
        source: "account_wire_max",
        label: "Elektronikus átutalás díja (max)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_wire_min = {
    props: {
        source: "account_wire_min",
        label: "Elektronikus átutalás díja (min)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_wire_percent = {
    props: {
        source: "account_wire_percent",
        label: "Elektronikus átutalás díja (%)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_wire_transaction_fee = {
    props: {
        source: "account_wire_transaction_fee",
        label: "Elektronikus átutalás tranzakciós díj (Ft)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_wire_transaction_percent = {
    props: {
        source: "account_wire_transaction_percent",
        label: "Elektronikus átutalás tranzakciós díj (%)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_wire_transaction_max = {
    props: {
        source: "account_wire_transaction_max",
        label: "Elektronikus átutalás tranzakciós díj max",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_wire_recurring_fee = {
    props: {
        source: "account_wire_recurring_fee",
        label: "Rendszeres átutalás díja (Ft)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_wire_recurring_max = {
    props: {
        source: "account_wire_recurring_max",
        label: "Rendszeres átutalás díja (max)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_wire_recurring_min = {
    props: {
        source: "account_wire_recurring_min",
        label: "Rendszeres átutalás díja (min)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_wire_recurring_percent = {
    props: {
        source: "account_wire_recurring_percent",
        label: "Rendszeres átutalás díja (%)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_wire_recurring_transaction_fee = {
    props: {
        source: "account_wire_recurring_transaction_fee",
        label: "Rendszeres átutalás díja tranzakciós díj (Ft)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_wire_recurring_transaction_percent = {
    props: {
        source: "account_wire_recurring_transaction_percent",
        label: "Rendszeres átutalás díja tranzakciós díj (%)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const account_wire_recurring_transaction_max = {
    props: {
        source: "account_wire_recurring_transaction_max",
        label: "Rendszeres átutalás díja tranzakciós díj (max)",
    },
    editComponent: NumberInput,
    showComponent: NumberField,
};

export const indulo_vallalkozas = {
    props: {
        source: "indulo_vallalkozas",
        label: "Induló vállalkozás",
        choices: [
            { id: 0, name: "Nem" },
            { id: 1, name: "Igen" },
        ]
    },
    defaultValue: {
        defaultValue: 0,
    },
    editComponent: SelectInput,
    showComponent: SelectField,
};

export const lezart_uzleti_ev = {
    props: {
        source: "lezart_uzleti_ev",
        label: "Lezárt üzleti év",
        choices: [
            { id: 0, name: "Nem" },
            { id: 1, name: "Igen" },
        ]
    },
    defaultValue: {
        defaultValue: 0,
    },
    editComponent: SelectInput,
    showComponent: SelectField,
};

export const baseDataFields = {
    0: [
        [
            minsum,
            maxsum,
            effective_sum,
        ],
        [
            minmonths,
            maxmonths,
            effective_months,
        ],
        [
            minage,
            maxage,
        ],
        [
            minincome,
            minworkmonth,
            inkhr,
            income2bank,
            partner_req,
            payment_period,
        ],
        [
            disbursement_percent,
            annual_interest,
            interest_period,
            interest_type,
            other_costs,
        ],
        [
            other_info,
            promo_offer,
            example_text,
        ],
        [
            calc_fix,
            calc_fix_extra,
            calc_monthly,
        ]
    ],
    11: [
        [
            minage,
            maxage,
        ],
        [
            real_estate_type,
            credit_type,
            empty_field,
            loan_annuity,
            interest_only_first_year,
        ],
        [
            valuation_fee,
            certificate_fee,
            map_fee,
            mortgage_fee,
            mortgage_cancellation_fee,
            transfer_fee,
        ],
        [
            minincome,
            minworkmonth,
            inkhr,
            income2bank,
            partner_req,
            health_insurance,
        ],
        [
            annual_interest,
            interest_period,
            interest_type,
            other_costs,
        ],
        [
            interest_rate_change_indicator,
            interest_surcharge_change_indicator
        ],
        [
            other_info,
            promo_offer,
            example_text,
        ],
        [
            calc_fix,
            calc_fix_extra,
            calc_monthly,
        ]
    ],
    26: [],
    37: [],
    38: [],
    47: [
        [
            credit_type_vallalkozoi,
        ],
        [
            fresh_company,
            business_years,
            equity_cover,
            owner_guarantee,
            guarantee_type
        ],
        [
            currency,
            decision_fee,
            administration_fee,
            contract_fee,
            payout_fee
        ],
        [
            real_interest,
            gov_interest_support,
        ],
        [
            garantiqa_fee,
            guarantor_name,
            surety_fee,
            checkup_fee,
            closing_fee,
            commitment_fee
        ],
        [
            late_fee,
            repayment_fee,
            full_payment_fee,
        ],
        [
            handling_fee,
            handling_fee_support,
            handling_fee_net,
        ],
        [
            other_comment,
            example_text,
        ],
        [
            calc_fix,
            calc_fix_extra,
            calc_monthly,
        ]
    ],
    53: [
        [
            minsum,
            maxsum,
        ],
        [
            minmonths,
            maxmonths,
        ],
        [
            minage,
            maxage,
        ],
        [
            minincome,
            minworkmonth,
            inkhr,
            income2bank,
            partner_req,
            health_insurance,
        ],
        [
            guarantee_fee,
            annual_interest,
            annual_interest_other,
            interest_period,
            interest_type,
            other_costs,
        ],
        [
            other_info,
            promo_offer,
            example_text,
        ],
        [
            calc_fix,
            calc_fix_extra,
            calc_monthly,
        ]
    ],
    65: [
        [
            minsum,
            maxsum,
        ],
        [
            minmonths,
            maxmonths,
        ],
        [
            minage,
            maxage,
        ],
        [
            minincome,
            minworkmonth,
            inkhr,
            income2bank,
            partner_req,
            payment_period,
        ],
        [
            disbursement_percent,
            annual_interest,
            interest_period,
            interest_type,
            other_costs,
        ],
        [
            other_info,
            promo_offer,
            example_text,
        ],
        [
            calc_fix,
            calc_fix_extra,
            calc_monthly,
        ]
    ],
    66: [
        [
            minage,
            maxage,
        ],
        [
            real_estate_type,
            credit_type,
        ],
        [
            valuation_fee,
            certificate_fee,
            map_fee,
            mortgage_fee,
            transfer_fee,
        ],
        [
            minincome,
            minworkmonth,
            inkhr,
            income2bank,
            partner_req,
            health_insurance,
        ],
        [
            annual_interest,
            interest_period,
            interest_type,
            other_costs,
        ],
        [
            other_info,
            promo_offer,
            example_text,
        ],
        [
            calc_fix,
            calc_fix_extra,
            calc_monthly,
        ]
    ],
    67: [
        [
            account_type,
        ],
        [
            minincome,
            minage,
            maxage,
        ],
        [
            cardname,
            monthly_costs,
            internet_bank,
        ],
        [
            ebkm,
            main_card_first_year_fee_card,
            main_card_second_year_fee_card,
            card_issueing_fee,
            account_opening_fee,
        ],
        [
            electronic_statement_fee,
            internetbank_fee,
            governmental_fee,
        ],
        [
            purchase_with_card,
            prompt_collection_fee,
            normal_wire_transfer_fee,
            regular_wire_transfer_fee,
            cash_withdraw_own_atm,
            cash_withdraw_foreign_atm,
        ],
        [
            example_text,
        ],
        [
            account_card_use_fee,
            account_collect_fee,
            account_collect_percent,
            account_collect_max,
            account_collect_min,
            account_collect_transaction_fee,
            account_collect_transaction_percent,
            account_collect_transaction_max,
        ],
        [
            account_abroad_atm_fee,
            account_abroad_atm_percent,
            account_abroad_atm_max,
            account_abroad_atm_min,
            account_abroad_atm_transaction_percent,
        ],
        [
            account_foreign_atm_fee,
            account_foreign_atm_percent,
            account_foreign_atm_max,
            account_foreign_atm_min,
            account_foreign_atm_transaction_percent,
        ],
        [
            account_own_atm_fee,
            account_own_atm_percent,
            account_own_atm_max,
            account_own_atm_min,
            account_own_atm_transaction_percent,
        ],
        [
            account_wire_fee,
            account_wire_max,
            account_wire_min,
            account_wire_percent,
            account_wire_transaction_fee,
            account_wire_transaction_percent,
            account_wire_transaction_max,
        ],
        [
            account_wire_recurring_fee,
            account_wire_recurring_max,
            account_wire_recurring_min,
            account_wire_recurring_percent,
            account_wire_recurring_transaction_fee,
            account_wire_recurring_transaction_percent,
            account_wire_recurring_transaction_max,
        ],
    ],
    68: [
        [
            account_type,
        ],
        [
            minincome,
            minage,
        ],
        [
            indulo_vallalkozas,
            lezart_uzleti_ev,
            cardname,
            monthly_costs,
            internet_bank,
        ],
        [
            ebkm,
            main_card_first_year_fee_card,
            main_card_second_year_fee_card,
            card_issueing_fee,
            account_opening_fee,
            package_fee,
        ],
        [
            electronic_statement_fee,
            internetbank_fee,
            governmental_fee,
        ],
        [
            purchase_with_card,
            prompt_collection_fee,
            normal_wire_transfer_fee,
            regular_wire_transfer_fee,
            cash_withdraw_own_atm,
            cash_withdraw_foreign_atm,
        ],
        [
            example_text,
        ],
        [
            account_card_use_fee,
            account_collect_fee,
            account_collect_percent,
            account_collect_max,
            account_collect_min,
            account_collect_transaction_fee,
            account_collect_transaction_percent,
            account_collect_transaction_max,
        ],
        [
            account_abroad_atm_fee,
            account_abroad_atm_percent,
            account_abroad_atm_max,
            account_abroad_atm_min,
            account_abroad_atm_transaction_percent,
        ],
        [
            account_foreign_atm_fee,
            account_foreign_atm_percent,
            account_foreign_atm_max,
            account_foreign_atm_min,
            account_foreign_atm_transaction_percent,
        ],
        [
            account_own_atm_fee,
            account_own_atm_percent,
            account_own_atm_max,
            account_own_atm_min,
            account_own_atm_transaction_percent,
        ],
        [
            account_wire_fee,
            account_wire_max,
            account_wire_min,
            account_wire_percent,
            account_wire_transaction_fee,
            account_wire_transaction_percent,
            account_wire_transaction_max,
        ],
        [
            account_wire_recurring_fee,
            account_wire_recurring_max,
            account_wire_recurring_min,
            account_wire_recurring_percent,
            account_wire_recurring_transaction_fee,
            account_wire_recurring_transaction_percent,
            account_wire_recurring_transaction_max,
        ],
    ],
    69: [
        [
            account_type_creditcard,
        ],
        [
            minage,
            minworkmonth,
            minincome,
        ],
        [
            min_credit,
            max_credit,
        ],
        [
            interest_type,
            monthly_costs,
            credit_min_repayment,
            annual_interest,
            credit_interest_monthly,
        ],
        [
            main_card_first_year_fee_card,
            main_card_second_year_fee_card,
        ],
        [
            no_interest_days,
            flat_rate_thm,
            credit_limit,
            cashback_max,
        ],
        [
            creditcard_groceries,
            creditcard_petrol,
            creditcard_home,
            creditcard_travel,
            creditcard_electronics,
            creditcard_dress,
            creditcard_culture,
            creditcard_sport,
        ],
        [
            example_text,
        ],
    ],
    70: [
        [
            account_type_creditcard,
        ],
        [
            minage,
            minworkmonth,
            minincome,
        ],
        [
            interest_type,
            monthly_costs,
            credit_min_repayment,
            annual_interest,
            credit_interest_monthly,
        ],
        [
            main_card_first_year_fee_card,
            main_card_second_year_fee_card,
        ],
        [
            no_interest_days,
            flat_rate_thm,
            credit_limit,
            cashback_max,
        ],
        [
            creditcard_groceries,
            creditcard_petrol,
            creditcard_home,
            creditcard_travel,
            creditcard_electronics,
            creditcard_dress,
            creditcard_culture,
            creditcard_sport,
        ],
        [
            example_text,
        ],
    ],
    1026: [
        [
            minmonths,
            maxmonths,
        ],
        [
            min_lekotheto_osszeg,
            max_lekotheto_osszeg,
        ],
        [
            currency,
            ebkm,
            interest_type,
            annual_interest,
        ],
        [
            kamatado,
            szocho,
            betetfeltores,
            other_comment_betet,
        ],
    ],
}

baseDataFields[72] = baseDataFields[11];

export const baseRatesFields = {
    0: [
        min_interest,
        max_interest,
        min_sum,
        max_sum,
        min_months,
        max_months,
        min_income,
        max_income,
        min_thm,
        max_thm,
        title,
    ],
    11: [
        min_interest,
        max_interest,
        min_sum,
        max_sum,
        min_months,
        max_months,
        min_income,
        max_income,
        min_thm,
        max_thm,
        title,
    ],
    26: [],
    37: [],
    38: [],
    47: [],
    53: [
        min_interest,
        max_interest,
        min_sum,
        max_sum,
        min_months,
        max_months,
        min_income,
        max_income,
        min_thm,
        max_thm,
        title,
    ],
    65: [
        min_interest,
        max_interest,
        min_sum,
        max_sum,
        min_months,
        max_months,
        min_income,
        max_income,
        min_thm,
        max_thm,
        title,
    ],
    66: [
        min_interest,
        max_interest,
        min_sum,
        max_sum,
        min_months,
        max_months,
        min_income,
        max_income,
        min_thm,
        max_thm,
        title,
    ],
    67: [
        min_income_bankaccount_private,
        max_income_bankaccount_private,
        monthly_costs,
    ],
    68: [
        min_income_bankaccount_company,
        max_income_bankaccount_company,
        monthly_costs,
        normal_wire_transfer_fee,
        account_wire_fee,
        account_wire_min,
        account_wire_max,
        account_wire_percent,
        account_wire_transaction_fee,
        account_wire_transaction_percent,
        account_wire_transaction_max,
        regular_wire_transfer_fee,
        account_wire_recurring_fee,
        account_wire_recurring_min,
        account_wire_recurring_max,
        account_wire_recurring_percent,
        account_wire_recurring_transaction_fee,
        account_wire_recurring_transaction_percent,
        account_wire_recurring_transaction_max,
        title,
    ],
    69: [
        min_income,
        max_income,
    ],
    70: [
        min_income,
        max_income,
    ],
    1026: [],
}

baseDataFields[72] = baseDataFields[11];
baseDataFields[1024] = baseDataFields[67];
baseDataFields[1031] = baseDataFields[53];
baseDataFields[1030] = baseDataFields[11];

baseRatesFields[72] = baseRatesFields[11];
baseRatesFields[1024] = baseRatesFields[67];
baseRatesFields[1031] = baseRatesFields[53];
baseRatesFields[1030] = baseRatesFields[11];